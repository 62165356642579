<template>
  <div class="indexPage">
    <div class="indexTop">
      <div class="txt">
        JHRON（聚和荣科技）是一家成立于北京海淀的中国计算机软
        件商，其业务主要涵盖应用软件，大数据和系统集成，自创办以来，一直为
        自动化，商务社交，电子商务等行业领域提供软件解决方案及开发服务，发
        展至今，公司业务也已经辐射天津，上海，青岛，和美国纽约。
      </div>
      <img class="imgTop" src="../assets/img/indexImg/JHR-pdf1.jpg" alt="">
    </div>
    <!-- 最新热点 -->
    <div class="hot">
      <div class="title">最新热点</div>
      <ul class="list clear">
        <li>
          <img src="../assets/img/indexImg/hotSpot.png" alt="">
          <div class="info">
            <p class="txt">工业智能化背景下制造业大数据的应用 </p>
            <p class="describe">随着科学技术的高速发展和广泛应用，工业
              智能化背景下的大数据应用成为制造业转型
              发展的重要工具，是工业改革和产业革…… </p>
            <div class="link_page" @click="linkFn()">
              <img class="link1" src="../assets/img/indexImg/link1.png" alt="">
              <img class="link2" src="../assets/img/indexImg/link2.png" alt="">
            </div>
          </div>
        </li>
        <li class="listShow">
          <img src="../assets/img/indexImg/hotSpot2.png" alt="">
          <div class="info">
            <p class="txt">JHRON(聚和荣科技)是一家成立于北京海淀的中国计算机软</p>
            <p class="describe">在当今快速发展的技术世界中,编程语言的重要性不言而喻。Java和Python是两种流行的编程语言,它们在软件开发、数据分…… </p>
            <div class="link_page" @click="linkFn()">
              <img class="link1" src="../assets/img/indexImg/link1.png" alt="">
              <img class="link2" src="../assets/img/indexImg/link2.png" alt="">
            </div>
          </div>
        </li>
        <li>
          <img src="../assets/img/indexImg/hotSpot.png" alt="">
          <div class="info">
            <p class="txt">工业智能化背景下制造业大数据的应用 </p>
            <p class="describe">随着科学技术的高速发展和广泛应用，工业
              智能化背景下的大数据应用成为制造业转型
              发展的重要工具，是工业改革和产业革…… </p>
            <div class="link_page" @click="linkFn()">
              <img class="link1" src="../assets/img/indexImg/link1.png" alt="">
              <img class="link2" src="../assets/img/indexImg/link2.png" alt="">
            </div>
            <!--<div class="box"></div>-->
          </div>
        </li>
        <li>
          <img src="../assets/img/indexImg/hotSpot2.png" alt="">
          <div class="info">
            <p class="txt">工业智能化背景下制造业大数据的应用 </p>
            <p class="describe">在当今快速发展的技术世界中,编程语言的重要性不言而喻。Java和Python是两种流行的编程语言,它们在软件开发、数据分……</p>
            <div class="link_page" @click="linkFn()">
              <img class="link1" src="../assets/img/indexImg/link1.png" alt="">
              <img class="link2" src="../assets/img/indexImg/link2.png" alt="">
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 团队 -->
    <div class="team clear">
      <img class="team_img" src="../assets/img/indexImg/team.jpg" alt="">
      <div class="team_info">
        <p class="use_txt">我们的团队</p>
        <div class="jhr">
          聚合荣技术团队有着5年的技术成次和积累，其中包
          含js,python,c++,php,wordpress等最新，最广泛的
          语言，有能力为客户提供多方位的技术开发业务，特
          别是在应用软件开发，海外软件代理开发，控制系统
          开发等领域有突出的优势，我们相信以信息技术为基
          础的人工智能，大数据，云计算代表了未来科技创新
          行业发展的浪潮，聚合荣勇于参与和推动科技进步并
          成为其中的一员，为全球客户提供高品质的开发服务
        </div>
      </div>
    </div>
    <!-- 服务 -->
    <div class="server">
      <p class="title">服务</p>
      <ul class="clear server_IT">
        <li class='postion_li'>
          应用软件开发
          <img src="../assets/img/indexImg/link1.png" alt="" class="link_one">
          <img src="../assets/img/indexImg/link2.png" alt="" class="line_two">
        </li>
        <li class="serve_info postion_li">控制系统开发
          <img src="../assets/img/indexImg/link1.png" alt="" class="link_one">
          <img src="../assets/img/indexImg/link2.png" alt="" class="line_two">
        </li>
        <li class='postion_li'>承揽海外开发业务
          <img src="../assets/img/indexImg/link1.png" alt="" class="link_one">
          <img src="../assets/img/indexImg/link2.png" alt="" class="line_two">
        </li>
        <li class='postion_li'>JHRON（聚和荣科技）
          <img src="../assets/img/indexImg/link1.png" alt="" class="link_one">
          <img src="../assets/img/indexImg/link2.png" alt="" class="line_two">
        </li>
      </ul>
    </div>
    <!-- 分支机构 -->
    <div class="branch">
      <p class="title">分支机构</p>
      <ul class="city clear">
        <li v-for="(item, index) in data" :key="index" @click="Jump(index)">
          <img :src="item.img" alt="">
          <p>{{ item.test }}</p>
        </li>
      </ul>
    </div>
  </div>
</template> 

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      data: [
        {
          img: require('../assets/img/indexImg/hotSpot2.png'),
          test: '天津'
        },
        {
          img: require('../assets/img/indexImg/hotSpot.png'),
          test: '上海'
        },
        {
          img: require('../assets/img/indexImg/hotSpot2.png'),
          test: '北京'
        },
        {
          img: require('../assets/img/indexImg/hotSpot.png'),
          test: '纽约'
        },

      ]
    };
  },
  methods: {
    Jump(index) {
      this.$router.push({ path: '/InstitutionalBranches', query: { id: index } })
    },
    // 热点事件
    linkFn() {
      this.$router.push({ path: '/news' })
    }
  },

  created() { },

  mounted() { },

  computed: {},

  watch: {},
};
</script>

<style lang='scss' scoped>
@import url('../assets/css/index.css');
</style>